
import { Component, Inject, Vue } from "vue-property-decorator";

import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import { SyncGatewayCredentialsToken } from "@/domain";

import { ISyncGatewayCredentialsService } from "@/services";

@Component
export default class TabletAccessTokens extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get syncGatewayCredentialsService() {
    return this.container.resolve<ISyncGatewayCredentialsService>(
      S.SYNC_GATEWAY_CREDENTIALS_SERVICE
    );
  }

  token: SyncGatewayCredentialsToken | null = null;
  loading: boolean = false;

  async getAccess() {
    try {
      this.loading = true;
      this.token = null;
      this.token = await this.syncGatewayCredentialsService.getExternalAccess();
    } finally {
      this.loading = false;
    }
  }
}
